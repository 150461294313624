import styled from 'styled-components';
import './loadAmplitude';
import { useEffect, useState } from 'react';
import amplitude from 'amplitude-js';
import moment from 'moment';
import Header from './components/Header';
import Button from './components/Button';
import devider from './assets/border.png';
import './App.css';
import InputWorryPage from './pages/InputWorryPage';
import Notice from './pages/Notice';
import InputPhonePage from './pages/InputPhonePage';
import Ready from './pages/Ready';
import { Footer } from './components/Footer';
import { useAsyncEffect } from './hooks/use-async-effect';
import { fetchUtmMap, UtmKey, getUrlParams, fetchAdequatePixel } from './utils/datautils';
import ReservePage from './pages/ReservePage/index';
import InputPhonePageReservation from './pages/InputPhonePageReservation';

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

const FinalMsg = styled.p`
  font-family: 'OK GUNG';
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 180%;
  text-align: center;
`;

function App() {
  useAsyncEffect(async () => {
    const source = getUrlParams('utm_source');
    if (source) {
      const utmMap = await fetchUtmMap();
      localStorage.setItem(UtmKey, JSON.stringify(utmMap));
    }
  }, []);

  useEffect(() => {
    const pixelId = fetchAdequatePixel();
    fbq('trackSingle', pixelId, 'PageView');

    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_main', { referral: 'postpay_painful_v2' });
  }, []);

  const [pageNum, setPageNum] = useState(0);
  const toNextPage = () => {
    setPageNum(prev => prev + 1);
  };

  const toReservePage = () => {
    setPageNum(prev => 5);
    amplitude.getInstance().logEvent('click_reservation', { referral: 'postpay_painful_v2' });
  };

  const [inputWorry, setInputWorry] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [reservationDate, setReservationDate] = useState(moment().format());

  const inputPhoneChangeHandler = event => {
    setInputPhone(prev => event.target.value);
  };

  const inputWorryChangeHandler = event => {
    setInputWorry(prev => event.target.value);
  };

  const readyStateHandler = () => {
    setPageNum(prev => prev + 1);
  };

  return (
    <>
      <Header />
      <div
        style={{
          position: 'relative',
          padding: '0 2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Devider src={devider} />

        {pageNum === 0 && (
          <img
            src={'https://tnndonnqnixjfqlzqhxv.supabase.co/storage/v1/object/public/public/landingpage/painful_v3.png'}
            width="100%"
            style={{ maxWidth: '37.2rem', marginTop: '1.7rem', marginBottom: '5rem' }}
            alt="메인"
          />
        )}
        {pageNum === 0 && (
          <img
            src={'https://tnndonnqnixjfqlzqhxv.supabase.co/storage/v1/object/public/public/landingpage/requests.gif'}
            style={{ width: '32rem', height: '22.9rem', marginTop: '-20rem', marginBottom: '1.7rem' }}
            alt="고객리스트"
          />
        )}
        {pageNum === 0 && (
          <FinalMsg>
            <span style={{ color: '#E1354C' }}>대신, 약속해주세요</span>
            <br />
            꼭, 고민이 깊은 분만
            <br />
            연락 부탁드립니다.
          </FinalMsg>
        )}
        {pageNum === 1 && (
          <InputWorryPage value={inputWorry} onChange={inputWorryChangeHandler} onButtonClick={toNextPage} />
        )}

        {pageNum === 0 && (
          <Button
            id="cta-start-writing"
            width="90%"
            backgroundColor="#E1354C"
            text="고민 해결하러 가기"
            color="#fff"
            style={{
              position: 'fixed',
              bottom: '0.5rem',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '2rem',
              textAlign: 'center',
              marginTop: '10rem',
              marginBottom: '2.5rem',
            }}
            onClickHandler={() => {
              ttq.track('ViewContent');
              toNextPage();
            }}
          />
        )}

        {pageNum === 2 && <Notice onButtonClick={toNextPage} onClickNo={toReservePage} />}
        {pageNum === 3 && (
          <InputPhonePage
            onReady={readyStateHandler}
            value={inputPhone}
            onChange={inputPhoneChangeHandler}
            worry={inputWorry}
          />
        )}
        {(pageNum === 4 || pageNum === 7) && <Ready isReserved={pageNum === 7} />}
        {pageNum === 5 && <ReservePage onButtonClick={toNextPage} onDateChange={setReservationDate} />}
        {pageNum === 6 && (
          <InputPhonePageReservation
            reservationDate={reservationDate}
            worry={inputWorry}
            onReady={() => {
              setPageNum(prev => 7);
            }}
          />
        )}
      </div>
      {pageNum === 0 && <Footer />}
    </>
  );
}

export default App;
