export const Footer = () => (
  <footer
    style={{
      fontSize: '1.5rem',
      color: '#353A40',
      lineHeight: 1.2,
      marginTop: '2rem',
      padding: '5rem 1.5rem',
    }}>
    러너스컴퍼니 주식회사 | 대표: 정승진 | 사업자등록번호: 121-87-02325 | 통신판매업신고: 제 2022-서울강남-00879호 |
    주소: 서울 강남구 논현로76길 6 4층 | Email : admin@learners.company | Copyright 2202. Learners Company Inc. All
    right reserved.
  </footer>
);
